import { css } from "styled-components"

export const Colors = {
	Primary: "#427EC1",
	PrimaryHover: "#4b90de",
	Secondary: "#88A45E",
	SecondaryText: "#556839",
	Accent: "#BDB15A",
	Warning: "#F0C159",
	Error: "#c04714",
	Background: "#e0e0e0",
	BackgroundBox: "#ffffff",
	BackgroundHover: "#efefef",
	BackgroundFocus: "#f7f7f7",
	Text: "#3f3f3f",
	TextWarning: "#4a3300",
	TextError: "#ffffff",
	Muted: "#585757",
	Border: "#6f6f6f",
	Header: "#353535",
	HeaderHightlight: "#4A4A4A",
	HeaderText: "#ffffff",
} as const

export const Sizes = {
	H1: "36px",
	H2: "24px",
	H3: "18px",
	Paragraph: "16px",
	Small: "14px",
	Important: "20px",
	HeaderHeight: "70px",
	HeaderText: "12px",
} as const

export const Borders = {
	Shadow: "0px 0px 15px 5px rgba(0,0,0,0.05)",
	ShadowFocus: "0px 3px 6px 0px rgba(0,0,0,0.25)",
	Radius: "10px",
	RadiusPill: "20px",
	Divider: "1px solid #e0e0e0",
} as const

export const Spacing = {
	Heading: "20px",
	Default: "10px",
	BoxPadding: "30px",
} as const
const MediaBox = css`
	@media only screen and (max-width: 767px) {
		width: 350px;
	}
	@media only screen and (min-width: 768px) {
		width: 400px;
	}
`
export const Presets = {
	Animate: css`
		.animate {
			left: ${Spacing.BoxPadding};
			right: ${Spacing.BoxPadding};
			&-enter {
				opacity: 0;
				transform: scaleY(0.1);
				&-active {
					position: absolute;
					width: 100%;
					opacity: 1;
					transform: scaleY(1);
					transition: opacity 500ms, transform 500ms;
				}
			}
			&-exit {
				opacity: 1;
				transform: scaleY(1);
				&-active {
					position: absolute;
					width: 100%;
					visibility: none;
					opacity: 0;
					transform: scaleY(0.1);
					transition: opacity 200ms, transform 200ms;
				}
			}
		}
	`,
	Modal: css`
		.modal {
			&-enter {
				opacity: 0;
				&-active {
					opacity: 1;
				}
			}
			&-exit {
				opacity: 1;
				&-active {
					visibility: none;
					opacity: 0;
					transition: all 0.2s;
				}
			}
		}
	`,
	BodyContainer: css`
		${MediaBox}
		background: ${Colors.BackgroundBox};
		color: ${Colors.Text};
		border-radius: ${Borders.Radius};
		position: relative;
		padding: ${Spacing.BoxPadding};
		box-shadow: ${Borders.Shadow};
		transition: min-height 600ms;
	`,
	MediaBox,
	MediaBoxIconWrapper: css`
		@media only screen and (max-width: 767px) {
			left: 125px;
		}
		@media only screen and (min-width: 768px) {
			left: 150px;
		}
		width: 100px;
		height: 100px;
		position: absolute;
		top: -50px;

		padding-bottom: ${Spacing.Default};
		border-radius: 50%;
		background: ${Colors.Primary};
		display: flex;
		align-items: center;
		justify-content: center;
	`,
} as const

const theme = {
	colors: Colors,
	sizes: Sizes,
	borders: Borders,
	spacing: Spacing,
	presets: Presets,
}
export default theme
