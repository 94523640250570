import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "react-datepicker/dist/react-datepicker.css"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import "./libs/analytics"

Bugsnag.start({
	apiKey: "f2f9e3ad85b1138c425a9d5643c6b9b8",
	plugins: [new BugsnagPluginReact()],
	releaseStage: process.env.NODE_ENV !== "production" ? "development" : "production",
})
Chargebee.init({
	site: process.env.REACT_APP_CHARGEBEE_SITE || "busstock",
	enableGATracking: true,
})
const ErrorBoundary = Bugsnag?.getPlugin("react")?.createErrorBoundary(React)

ReactDOM.render(
	ErrorBoundary ? (
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	) : (
		<App />
	),
	document.getElementById("root"),
)

serviceWorker.unregister()
