import React from "react"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import theme from "./libs/theme"
import { Helmet } from "react-helmet"
import Config from "./libs/config"
import "../node_modules/react-toggle/style.css"
import getSubdomain from "./libs/getSubdomain"
import SyncLoader from "react-spinners/SyncLoader"
import FlexWrapper from "./components/FlexWrapper"
import { QueryClient, QueryClientProvider } from "react-query"
import lazyRetry from "./libs/lazyRetry"

const queryClient = new QueryClient()
const AppAdmin = React.lazy(() => lazyRetry(() => import("./AppAdmin")))
const AppList = React.lazy(() => lazyRetry(() => import("./AppList")))
const ShoppingList = React.lazy(() => lazyRetry(() => import("./ShoppingList")))
const PublicWebsite = React.lazy(() => lazyRetry(() => import("./PublicWebsite")))

const fontFamily = `'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`

const GlobalStyle = createGlobalStyle`
  body,html,#root {
    height: 100%;
    margin: 0;
    font-family: ${fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  textarea {
    font-family: ${fontFamily};
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .__react_component_tooltip {
    padding: 4px 10.5px;
  }
  ${({ theme }) => theme.presets.Animate}
  ${({ theme }) => theme.presets.Modal}
`
const Background = styled.div`
	min-height: 100%;
	background: ${({ theme }) => theme.colors.Background};
	display: flex;
	position: relative;
	@media print {
		background: none;
	}
`
const AppPadding = styled.div`
	display: flex;
	flex: 1;
	min-height: 100%;
	padding: 20px;
	max-width: 100%;
`

function subdomainSwitch() {
	const subdomain = getSubdomain()
	switch (subdomain) {
		case "admin":
		case "dashboard":
			return (
				<AppPadding>
					<AppAdmin />
				</AppPadding>
			)
		case "www":
		case "":
			return <PublicWebsite />
		case "shop":
			return (
				<AppPadding>
					<ShoppingList />
				</AppPadding>
			)
		default:
			return (
				<AppPadding>
					<AppList subdomain={subdomain} />
				</AppPadding>
			)
	}
}

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<Background>
					<GlobalStyle />
					<Helmet titleTemplate={`%s | ${Config.appName}`} defaultTitle={Config.appName} />
					<React.Suspense
						fallback={
							<FlexWrapper style={{ padding: 20 }}>
								<div>
									<SyncLoader color="#666" size={8} />
								</div>
							</FlexWrapper>
						}
					>
						{subdomainSwitch()}
					</React.Suspense>
				</Background>
			</ThemeProvider>
		</QueryClientProvider>
	)
}

export default App
